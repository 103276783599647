import React, { useState, useEffect } from "react";
import './App.css'; 
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import Skeleton from '@material-ui/lab/Skeleton';
import Autocomplete from "react-google-autocomplete";
//Datepicker Range
import TextField from '@material-ui/core/TextField';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import Box from '@material-ui/core/Box';



const Travelytics_Service_URL = 'https://travelytics-4n6mxud3qa-uc.a.run.app'
// const Conceptv3_service = 'https://flights-pu2pxlqsba-uc.a.run.app'

function App() {

  const date = new Date();
  const smartDepart = `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${date.getDay()+18}`
  const smartReturn = `${date.getFullYear()}-${('0' + (date.getMonth()+1)).slice(-2)}-${date.getDay()+22}`


  const [loading, setLoading] = useState(false)
  const [newview, setNewView] = useState(false)
  const [depart, setDepart] = useState(null);
  const [destination, setDestination] = useState(null);
  const [flights, setFlights] = useState([])
  const [hotels, setHotels] = useState([])
  const [cars, setCars] = useState([]); 
  const [travels, setTravels] = useState([]);
  const [departCode, setDepartCode] = useState(null);
  const [destCode, setDestCode] = useState(null);
  const [rentalPrice, setRentalPrice] = useState(0);
  const [flightPrice, setFlightPrice] = useState(0);
  const [hotelPrice, setHotelPrice] = useState(0);
  const [codes, setCodes] = useState({}); 
  const [searchError, setSearchError] = useState(null); 
  const [outboundDate, setoutboundDate] = useState(smartDepart);
  const [inBoundDate, setinBoundDate] = useState(smartReturn);

  const [value, setValue] = useState([smartDepart, smartReturn]);


  useEffect(() => {
    // Update the document title using the browser API
    axios.get(`${Travelytics_Service_URL}`)
  }, []);

  async function handleSearch(e) {
    e.preventDefault()
    setLoading(true)
    setSearchError(null)
    resetTripEstimate();      
    setNewView(true)

    var depart = new Date(value[0]);
    setoutboundDate(depart.toISOString().split('T')[0])

    var inbound = new Date(value[1]);
    setinBoundDate(inbound.toISOString().split('T')[0])

    // alert(date.toISOString().split('T')[0])


    const response = await handleGetSearch()
    if (response.data) {
      // alert(`deprt: ${response.data.departAirportCode}, dest: ${response.data.destinationAirportCode}`)
      let departAirportCode = response.data.departAirportCode
      let departMunicipalityName = response.data.departMunicipalityName
      let destinationAirportCode = response.data.destinationAirportCode
      let destinationMunicipalityName = response.data.destinationMunicipalityName
      
      setSkeletons();
      setTravels([{}])


      setDepartCode(departAirportCode)
      setDestCode(destinationAirportCode)

      handleGetFlights(departAirportCode, destinationAirportCode)
      handleGetHotels(destinationMunicipalityName)
      handleGetCars(destinationAirportCode)

      setCodes({
        departAirportCode: response.data.departAirportCode, 
        departMunicipalityName: response.data.departMunicipalityName,
        destinationAirportCode: response.data.destinationAirportCode,
        destinationMunicipalityName: response.data.destinationMunicipalityName,
      })
      setLoading(false)
      // handleResults()
    } else if (response.error !== null) {
      setSearchError(response.error)
      setLoading(false)
    }
    
    
  }


  function resetTripEstimate(){
    setFlightPrice(0)
    setHotelPrice(0)
    setRentalPrice(0)
  }

  function handleGetSearch() {
    setLoading(true)

    const response = axios.get(`${Travelytics_Service_URL}/search`,  { 
      params: {
        depart: depart, 
        destination: destination,
        outboundDate: outboundDate, 
        inBoundDate: inBoundDate
    }})
    .then(response => {
      return {'data': response.data}
    })
    .catch((error) => {

      alert(error)
      return ({'error': 'Results not found. The city may not have a nearby airport or we missed our API vendor payments 😄 💸. Try searching for major cities with known airports.'})
    })
    return response
  }

  function handleGetFlights(source, destination) {
    setLoading(true)
    axios.get(`${Travelytics_Service_URL}/flight`,  { 
      params: {
        source: source, 
        destination: destination,
        outboundDate: outboundDate, 
        inBoundDate: inBoundDate
    }})
    .then((response) => {
      setFlights([{
        price: response.data, 
        depart: source,
        destination: destination, 
        outboundDate: outboundDate, 
        inBoundDate: inBoundDate,
        loading: false,
      }])
      setFlightPrice(response.data*2)
      // setLoading(false)
    }).catch((error) => {
      // setLoading(false)
      setFlights([{
        loading: 500,
      }])
    })
  }

  function handleGetHotels(destinationCity) {
    setLoading(true)
    axios.get(`${Travelytics_Service_URL}/hotel`,  { 
      params: {
        numAdults: 1, 
        destination: destinationCity,
        checkIn: outboundDate, 
        checkOut: inBoundDate
    }})
    .then((response) => {
      setHotels([{
        price: response.data, 
        destination_city: destinationCity, 
        outboundDate: outboundDate, 
        inBoundDate: inBoundDate,
        loading: false,
      }])
      // setLoading(false)
      setHotelPrice(response.data)
    }).catch((error) => {
      // setLoading(false)
      setHotels([{
        loading: 500,
      }])
    })
  }

  function setSkeletons() {
    setFlights([{
      loading: true,
    }]); 

    setHotels([{
      loading: true,
    }])

    setCars([{
      loading: true,
    }])
  }

  function handleGetCars(destinationCity) {
    setLoading(true)
    axios.get(`${Travelytics_Service_URL}/car`,  { 
      params: {
        location_pickup: destinationCity,
        location_return: destinationCity,
        date_time_pickup: outboundDate,
        date_time_return: inBoundDate,
    }})
    .then((response) => {
      setCars([{
        vehicleTotalPrice: response.data.vehicleTotalPrice,
        vehicleDailyBasePrice: response.data.vehicleDailyBasePrice, 
        destination_city: destinationCity, 
        outboundDate: outboundDate, 
        inBoundDate: inBoundDate,
        vehicleCapcity: response.data.vehicleCapcity,
        loading: false,
      }])
      // setLoading(false)
      setRentalPrice(response.data.vehicleTotalPrice,)
    }).catch((error) => {
      // setLoading(false)
      setCars([{
        loading: 500,
      }])
    })  
  }



  let flight = flights.map(data => {
    if (data.loading === true) {
      return (
        <>
          <h1 style={styles.headerTitle}>Flights</h1>
          <div className={'card'} >
            <div style={{ width: '70%', display: 'flex', justifyContent: 'center', flexDirection:'column'}}>
              <Skeleton variant="rect" width={210} height={80} />
              <Skeleton width="60%" />
              <Skeleton width="80%" />
            </div>
          </div>
        </>
      )
    } else if (data.loading === 500) {
      
      return (
        <>
  
        <h1 style={styles.headerTitle}>Flights</h1>
        <div className={'card'}>
          <div style={{display:'flex', flexDirection: 'column', width: '70%', marginLeft: '10%'}}>
            <p style={{fontWeight:'bold', fontSize:20}}>No results found</p>
            <p style={{color:"gray"}}>{outboundDate} to {inBoundDate} </p>
  
          </div>
          <div style={{display:'flex', flexDirection: 'column', marginRight:'10%'}}>
            {/* <p style={{fontSize:20}}>${parseFloat(flightPrice) + parseFloat(rentalPrice) + parseFloat(hotelPrice)} Total </p> */}
          </div>
        </div>
        <small>* the availability may vary for the location and dates</small>
  
        </>
      )
    } else {
      return (<>
        <h1 style={styles.headerTitle}>Flights</h1>
        <a className={'card'} key={data.id} href={`https://www.kayak.com/flights/${departCode}-${destCode}/${outboundDate}/${inBoundDate}?sort=bestflight_a`} target={"_blank"}>
          <div style={{display:'flex', flexDirection: 'column', width: '70%', marginLeft: '10%'}}>
            <p style={{fontWeight:'bold', fontSize:20}}>{departCode} to {destCode}</p>
            <p style={{color:"gray"}}>{outboundDate} to {inBoundDate} </p>

          </div>
          <div style={{display:'flex', flexDirection: 'column', marginRight:'10%'}}>
            <p style={{fontSize:20}}>${data.price*2} RT </p>
          </div>
        </a>
      </>)
    }
  })

  let car = cars.map(data => {
    if (data.loading === true) {
      return (
        <>
            <h1 style={styles.headerTitle}>Rental Cars</h1>
          <div className={'card'} >
            <div style={{ width: '70%', display: 'flex', justifyContent: 'center', flexDirection:'column'}}>
              <Skeleton variant="rect" width={210} height={80} />
              <Skeleton width="60%" />
              <Skeleton width="80%" />
            </div>
          </div>
        </>
      )
    } else if (data.loading === 500) {
      
      return (
        <>
  
        <h1 style={styles.headerTitle}>Rental Cars</h1>
        <div className={'card'}>
          <div style={{display:'flex', flexDirection: 'column', width: '70%', marginLeft: '10%'}}>
            <p style={{fontWeight:'bold', fontSize:20}}>No results found</p>
            <p style={{color:"gray"}}>{outboundDate} to {inBoundDate} </p>
  
          </div>
          <div style={{display:'flex', flexDirection: 'column', marginRight:'10%'}}>
            {/* <p style={{fontSize:20}}>${parseFloat(flightPrice) + parseFloat(rentalPrice) + parseFloat(hotelPrice)} Total </p> */}
          </div>
        </div>
        <small>* the availability may vary for the location and dates</small>
  
        </>
      )
  
  
    } else {
    return (<>
      <h1 style={styles.headerTitle}>Rental Cars</h1>
      <a className={'card'} key={data.id} href={`https://www.kayak.com/cars/${destCode}/${outboundDate}/${inBoundDate}?sort=rank_a`} target={"_blank"}>
        <div style={{display:'flex', flexDirection: 'column', width: '70%', marginLeft: '10%'}}>
          <p style={{fontWeight:'bold', fontSize:20}}>{codes.destinationMunicipalityName}</p>
          <p style={{color:"gray"}}>{outboundDate} to {inBoundDate} </p>
        </div>
        <div style={{display:'flex', flexDirection: 'column', marginRight:'10%'}}>
          <p style={{fontSize:20}}>${data.vehicleTotalPrice} Total</p>
        </div>
      </a>
      <small>* estimate for rental car pickup at {destCode} airport</small>
    </>)
    }
  })

  let hotel = hotels.map(data => {
    if (data.loading === true) {
      return (
        <>
          <h1 style={styles.headerTitle}>Hotels</h1>
          <div className={'card'} >
            <div style={{ width: '70%', display: 'flex', justifyContent: 'center', flexDirection:'column'}}>
              <Skeleton variant="rect" width={210} height={80} />
              <Skeleton width="60%" />
              <Skeleton width="80%" />
            </div>
          </div>
        </>
      )
    } else if (data.loading === 500) {
      
    return (
      <>

      <h1 style={styles.headerTitle}>Hotels</h1>
      <div className={'card'}>
        <div style={{display:'flex', flexDirection: 'column', width: '70%', marginLeft: '10%'}}>
          <p style={{fontWeight:'bold', fontSize:20}}>No results found</p>
          <p style={{color:"gray"}}>{outboundDate} to {inBoundDate} </p>

        </div>
        <div style={{display:'flex', flexDirection: 'column', marginRight:'10%'}}>
          {/* <p style={{fontSize:20}}>${parseFloat(flightPrice) + parseFloat(rentalPrice) + parseFloat(hotelPrice)} Total </p> */}
        </div>
      </div>
      <small>* the availability may vary for the location and dates</small>

      </>
    )


    } else {
      return (<>
        <h1 style={styles.headerTitle}>Hotels</h1>
        <a className={'card'} key={data.id} href={ `https://www.kayak.com/hotels/${codes.destinationMunicipalityName}/${outboundDate}/${inBoundDate}/1adults?sort=rank_a`} target={"_blank"}>
          <div style={{display:'flex', flexDirection: 'column', width: '70%', marginLeft: '10%'}}>
            <p style={{fontWeight:'bold', fontSize:20}}>{codes.destinationMunicipalityName}</p>
            <p style={{color:"gray"}}>{outboundDate} to {inBoundDate} </p>
          </div>
          <div style={{display:'flex', flexDirection: 'column', marginRight:'10%'}}>
            <p style={{fontSize:20}}>${data.price} Total </p>
          </div>
        </a>
      </>)
    }
  })

  let travel = travels.map(data => {
  return (
    <>
      <h1 style={styles.headerTitle}>Trip Estimate</h1>
      <div className={'card'}>
        <div style={{display:'flex', flexDirection: 'column', width: '70%', marginLeft: '10%'}}>
          <p style={{fontWeight:'bold', fontSize:20}}>{codes.departMunicipalityName} to {codes.destinationMunicipalityName}</p>
          <p style={{color:"gray"}}>{outboundDate} to {inBoundDate} </p>

        </div>
        <div style={{display:'flex', flexDirection: 'column', marginRight:'10%'}}>
          <p style={{fontSize:20}}>${parseFloat(flightPrice) + parseFloat(rentalPrice) + parseFloat(hotelPrice)} Total </p>
        </div>
      </div>
      <small>* estimate does not include activities and dining</small>
    </>
  )})





  return (
    <>
      <div className={'logo'} style={{display:'flex', flexDirection:'row',   alignItems:"center",  fontWeight:'bold', fontSize:'28px',}}>
      <p style={{marginLeft: '4%',}}>Trip</p><p style={{color:"orange"}}>1</p><p style={{color:"green"}}>2</p><p style={{color:"maroon"}}>3</p><p style={{color:"navy"}}>4</p>

      </div>
      <div style={styles.container}>
      <div >
        {newview && <></>}
        {!newview && 
          <>
           <p className={'headline'}>Explore your next travel destination</p>
           <p className={'subject'}>Quickly estimate trip costs: flight, hotel and rental car.</p>
          </>
        }
       
      </div>


      <form
       id={'form'} onSubmit={handleSearch} style={{flexBasis:"100%", width:"95vw", justifyContent:'center', display:'flex', flexWrap:'wrap', flexDirection:'row', alignItems:"center", marginBottom:'15px'}}>
        

        <div style={{display:"flex", flexDirection:"column"}}>
          <label style={styles.label}>Depart</label>
          <Autocomplete
            className={'search-input'}
            style={styles.input}
            apiKey={'AIzaSyBj8Ny65Trq4EcG4l3hm9fbxzSZ3V9vFR8'}
            placeholder={'Where from'}
            onPlaceSelected={(place) => {
              console.log(place.formatted_address)
                setDepart(place.formatted_address)
            }}
          />   
        </div> 


        <div style={{display:"flex", flexDirection:"column"}}>
          <label style={styles.label}>Destination</label>
          <Autocomplete
            className={'search-input'}
            style={styles.input}
            apiKey={'AIzaSyBj8Ny65Trq4EcG4l3hm9fbxzSZ3V9vFR8'}
            placeholder={'Where to'}
            onPlaceSelected={(place) => {
              setDestination(place.formatted_address)
            }}
          />   
        </div> 


      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        startText="Depart date"
        endText="Return date"
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        renderInput={(startProps, endProps) => (
          <React.Fragment>
            <div style={{display:"flex", flexDirection:"column"}}>
              <label style={styles.label}>Depart Date</label>
              <input ref={startProps.inputRef} style={styles.date}  className={'search-input'} {...startProps.inputProps} />
            </div>
            {/* <Box sx={{ mx: 1 }}> to </Box> */}
            <div style={{display:"flex", flexDirection:"column"}}>
              <label style={styles.label}>Return Date</label>
              <input ref={endProps.inputRef} style={styles.date}  className={'search-input'} {...endProps.inputProps} />
            </div>
          </React.Fragment>
        )}
      />
    </LocalizationProvider>





        <div style={{display:"flex", flexDirection:"column"}}>
          <label style={styles.label}>{'\n'}</label>
          <button
            className={'search-input'}
            disabled={!depart || !destination  || loading}
            style={!depart || !destination || loading ? styles.disabled : styles.searchButton}
          >
                {loading && <i className="fa fa-spinner fa-spin"/>}
                {!loading && <span><SearchIcon style={{fontSize:32, fontWeight:'bold'}}/></span>}
            </button> 
        </div>
        <div style={{width:'100%'}}>
          <p style={{color:'red', textAlign:'center'}}>{searchError}</p>
      </div>
        
      </form>
      <div style={styles.responseContainer}>
        {travel}
      </div>


      <div style={styles.responseContainer}>
        {flight}
      </div>

      <div style={styles.responseContainer}>
        {car}
      </div>

      <div style={styles.responseContainer}>
        {hotel}
      </div>
    </div>
  </>
  );
}




export default App;


const styles = {
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'space-around',
    
    // height: '100vh',
    width: '100vw'
  },

  headerTitle: {
    fontSize:32,
    fontStyle: 'italic'
  },


  label: {
    fontWeight: 'bold', 
    fontSize: 24,
  }, 

  form: {
    display: 'flex', 
    flexDirection:'row',
  },

  transactionCard: {
    backgroundColor:'white',
    display: 'flex', 
    marginBottom:"10px", 
    borderRadius: '10px',
    flexDirection:'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    height: 200,
    boxShadow: "0 5px 10px rgba(154,160,185,.05), 0 15px 40px rgba(166,173,201,.2)", 
  },

  input: {
    width: 300, 
    height: 50,
    marginTop: 10, 
    marginBottom: 20, 
    fontSize: 24,
    paddingLeft: 15,
    borderRadius:4,
    marginRight:5,
    marginLeft:5,
    backgroundColor: '#F0F3F5',
    border: '1px solid transparent'
  },
  date: {
    width: 200, 
    height: 50,
    marginTop: 10, 
    marginBottom: 20, 
    fontSize: 24,
    paddingLeft: 15,
    borderRadius:4,
    marginRight:5,
    marginLeft:5,
    backgroundColor: '#F0F3F5',
    border: '1px solid transparent'
  },

  table: {
    width: '80%',
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: 'white',
    borderRadius:'20px', 
    height:'100%'
  },

  tableHeader: {
    width: '80%',
  },

  tableContainer: {
    display:'flex',
    flexDirection: 'column',
    flexBasis:'80%',
    height: '70vh',
    width: '80%',
    justifyContent:'center',
    backgroundColor: 'white',
    overflow: 'scroll', 
    padding: 30,
  },

  responseContainer: {
    width: '40%',
    backgroundColor: 'white',
    overflow: 'scroll', 
    padding: 30,
  },

  tr: {
    display: 'flex', 
    flexDirection :'row',
    justifyContent:'space-around',
    alignItems: 'center', 
    height: '60px', 
    backgroundColor: 'white',
    borderRadius:'20px',
  },

  header: {
    fontSize: 40,
    fontWeight: 'bold', 
  }, 

  subheader: {
    fontSize: 24,
  },

  dynamicNumber: {
    fontSize: 60,
    fontWeight: 'bold',
    marginBottom: '20%',
  },

  buttonCounter: {
    backgroundColor: 'black', 
    color: 'white', 
  }, 


  buttonContainer: {
    display: 'flex',
    flexDirection: 'row', 
    justifyContent: 'space-around',
    width: '40%' 
   }, 

  roundButton1: {
    width: 210,
    height: 60,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 10,
    borderRadius: 15,
    backgroundColor: 'black',
    color: 'white', 
    cursor: 'pointer',
    fontSize: 24,
    fontWeight: 'bold'
  },

  searchButton: {
    width: 60,
    height: 52,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 10,
    borderRadius: 4,
    backgroundColor: 'black',
    color: 'white', 
    cursor: 'pointer',
    // fontSize: 8,
    fontWeight: 'bold',
    marginTop:'18px'
  },



  disabled: {
    width: 60,
    height: 52,
    justifyContent: 'center',
    alignItems: 'center',
    // padding: 10,
    borderRadius: 8,
    backgroundColor: '#cccccc',
    color: 'black', 
    cursor: 'pointer',
    marginTop:'18px',

    // fontSize: 8,
    fontWeight: 'bold'
  },

};



{/* 
        <div style={{display:"flex", flexDirection:"column"}}>
          <label style={styles.label}>Depart Date</label>
          <input className={'search-input'} style={styles.input} type={'date'}  defaultValue={outboundDate}   onChange={e => alert(e.target.value)}/>
        </div>    


        <div style={{display:"flex", flexDirection:"column"}}>
          <label style={styles.label}>Return Date</label>
          <input className={'search-input'} style={styles.input} type={'date'}  value={inBoundDate}  onChange={e => setinBoundDate(e.target.value)}/>
        </div>    */}